import { useEffect, useState } from 'react';

export type SearchParamFilters = {
  [key: string]: Array<string | number | boolean>;
};

export const PAGINATION_LIMIT = 30;

export type MiddlewareSearchParams = {
  filterBy?: Record<string, Set<string | number | boolean>>;
  offset?: number;
  orderBy?: string;
  orderByDirection?: 'ASC' | 'DESC';
  q?: string;
  recordLimit?: number;
};

export const getSearchParamString = ({
  filterBy = {},
  offset,
  orderBy,
  orderByDirection,
  q,
  recordLimit,
}: MiddlewareSearchParams) => {
  const params = new URLSearchParams();

  Object.entries(filterBy).forEach(([name, vals]) =>
    vals.forEach((val) => {
      params.append('filterBy', `${name}~${val}`);
    })
  );

  params.append('offset', (offset || 0).toString());
  params.append('recordLimit', (recordLimit ?? PAGINATION_LIMIT).toString());

  if (q) params.append('q', q);

  if (orderBy && orderByDirection) {
    params.append('orderBy', encodeURIComponent(orderBy));
    params.append('orderByDirection', encodeURIComponent(orderByDirection));
  }

  return params.toString();
};

export const useMiddlewareSearchParams = (defaultParams: MiddlewareSearchParams = {}, baseOffset?: number) => {
  const [searchParams, setSearchParams] = useState<MiddlewareSearchParams>(defaultParams);

  useEffect(() => {
    setSearchParams({
      ...defaultParams,
      ...searchParams,
      filterBy: { ...defaultParams.filterBy, ...searchParams.filterBy },
    });
  }, [getSearchParamString(defaultParams)]);

  useEffect(() => {
    setSearchParams({ ...searchParams, offset: baseOffset });
  }, [baseOffset]);

  /**
   * Add a single filtering key/value pair
   */
  const addFilter = (key: string, value: string | number | boolean, keepExistingFilters = true) => {
    if (searchParams.filterBy?.key?.has(value)) return;

    const newFilters = { ...searchParams.filterBy };

    if (key in newFilters && !keepExistingFilters) delete newFilters[key];

    if (key in newFilters) newFilters[key].add(value);
    else newFilters[key] = new Set([value]);

    // Set offset to `baseOffset` to reset page when a filter is applied
    setSearchParams({ ...searchParams, filterBy: newFilters, offset: baseOffset });
  };

  /**
   * Remove all filters with a given 'key' value
   */
  const clearFilter = (key: string) => {
    if (!searchParams.filterBy?.[key]) return;

    const newFilters = { ...searchParams.filterBy };
    delete newFilters[key];

    // Set offset to `baseOffset` to reset page when a filter is removed
    setSearchParams({ ...searchParams, filterBy: newFilters, offset: baseOffset });
  };

  /**
   * Add multiple filtering values under the same key.
   */
  const addFilters = (key: string, values: Array<string | number | boolean>, keepExistingFilters = true) => {
    if (values.every((value) => searchParams.filterBy?.key?.has(value))) return;

    const newFilters = keepExistingFilters ? { ...searchParams.filterBy } : {};

    values.forEach((value) => {
      if (key in newFilters) newFilters[key].add(value);
      else newFilters[key] = new Set([value]);
    });
    setSearchParams({ ...searchParams, filterBy: newFilters, offset: baseOffset });
  };

  const setPage = (page: number) => {
    setSearchParams({ ...searchParams, offset: (baseOffset || 0) + (page - 1) * PAGINATION_LIMIT });
  };

  const setSearchQuery = (query: string) => {
    setSearchParams({ ...searchParams, q: query?.trim() });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [searchParams]);

  return {
    addFilter,
    addFilters,
    page: ((searchParams.offset || 0) - (baseOffset || 0)) / PAGINATION_LIMIT + 1,
    setPage,
    searchQuery: searchParams.q,
    setSearchQuery,
    searchParamString: getSearchParamString(searchParams),
    clearFilter,
  };
};
export default useMiddlewareSearchParams;

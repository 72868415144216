import Router from 'next/router';
import { useEffect } from 'react';

import { LoadingPanel } from 'components/Spinner/Spinner';

import useApplications, { APPLICANT_ALL_DRAFT_APPLICATIONS_FILTER } from 'hooks/useApplications';
import useStatus from 'hooks/useStatus';
import { getHomepageUrl } from 'utils/url-library';

const RedirectUserPage = () => {
  const { user } = useStatus();
  const { data: applications } = useApplications(APPLICANT_ALL_DRAFT_APPLICATIONS_FILTER);

  useEffect(() => {
    if (user && applications) {
      const redirectUrl = getHomepageUrl(user.isResearchOfficeUser, applications);
      Router.push(redirectUrl);
    }
  }, [applications, user]);

  return <LoadingPanel className="min-h-[100vh]">Redirecting...</LoadingPanel>;
};
export default RedirectUserPage;

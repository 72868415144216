import useSWR from 'swr';

import useMiddlewareSearchParams, { MiddlewareSearchParams } from './useMiddlewareSearchParams';

import { getAllApplications } from 'utils/api/application';
import type { Application, ApplicationWithStatus, DisplayStatus } from 'utils/api/application';
import { mapSalesforceStatusToApplicationStatus } from 'utils/status';

const RO_NON_ACTIONABLE_SUBMITTED_STATUSES: DisplayStatus[] = [
  'RECEIVED',
  'SHORTLISTING',
  'EXPERT_REVIEW',
  'APPROVED',
  'AWARD_ACCEPTED',
  'AWARD_STARTING_SOON',
];

const RO_ACTIONABLE_SUBMITTED_STATUSES: DisplayStatus[] = [
  'DECISION',
  'DECISION_INTERVIEW',
  'DECISION_RECOMMENDATION',
  'AWARD_OFFERED',
];

const SUBMITTED_STATUSES: DisplayStatus[] = [
  'RECEIVED',
  'SHORTLISTING',
  'EXPERT_REVIEW',
  'DECISION',
  'DECISION_INTERVIEW',
  'DECISION_RECOMMENDATION',
  'APPROVED',
  'AWARD_OFFERED',
  'AWARD_ACCEPTED',
  'AWARD_STARTING_SOON',
];

const PAST_STATUSES: DisplayStatus[] = ['ACTIVE', 'COMPLETED', 'CLOSED', 'NOT_PROGRESSED'];

export const SUBMITTED_APPLICATIONS_FILTER: MiddlewareSearchParams = {
  filterBy: {
    displayStatus: new Set(SUBMITTED_STATUSES),
  },
  orderBy: 'submissionDate',
  orderByDirection: 'DESC',
};

export const PAST_APPLICATIONS_FILTER: MiddlewareSearchParams = {
  filterBy: {
    displayStatus: new Set(PAST_STATUSES),
  },
  orderBy: 'submissionDate',
  orderByDirection: 'DESC',
};

export const ACTIONABLE_SUBMITTED_APPLICATIONS_FILTER: MiddlewareSearchParams = {
  filterBy: {
    displayStatus: new Set(RO_ACTIONABLE_SUBMITTED_STATUSES),
  },
  orderBy: 'submissionDate',
  orderByDirection: 'DESC',
};

export const NON_ACTIONABLE_SUBMITTED_APPLICATIONS_FILTER: MiddlewareSearchParams = {
  filterBy: {
    displayStatus: new Set(RO_NON_ACTIONABLE_SUBMITTED_STATUSES),
  },
  orderBy: 'submissionDate',
  orderByDirection: 'DESC',
};

export const RO_ACTIONABLE_DRAFT_APPLICATIONS_FILTER: MiddlewareSearchParams = {
  filterBy: { displayStatus: new Set(['STARTED']), submissionStatus: new Set(['withResearchOffice']) },
  orderBy: 'deadline',
  orderByDirection: 'ASC',
};

export const RO_NON_ACTIONABLE_DRAFT_APPLICATIONS_FILTER: MiddlewareSearchParams = {
  filterBy: {
    displayStatus: new Set(['STARTED']),
    submissionStatus: new Set([
      'withApplicant',
      'withWellcome',
      'returnedToApplicant',
      'returnedToApplicantFromWellcome',
    ]),
  },
  orderBy: 'deadline',
  orderByDirection: 'ASC',
};

export const APPLICANT_ACTIONABLE_DRAFT_APPLICATIONS_FILTER: MiddlewareSearchParams = {
  filterBy: {
    displayStatus: new Set(['STARTED']),
    submissionStatus: new Set(['returnedToApplicant', 'returnedToApplicantFromWellcome']),
  },
  orderBy: 'deadline',
  orderByDirection: 'ASC',
};

export const APPLICANT_NON_ACTIONABLE_DRAFT_APPLICATIONS_FILTER: MiddlewareSearchParams = {
  filterBy: {
    displayStatus: new Set(['STARTED']),
    submissionStatus: new Set(['withApplicant', 'withWellcome', 'withResearchOffice']),
  },
  orderBy: 'deadline',
  orderByDirection: 'ASC',
};

export const APPLICANT_ALL_DRAFT_APPLICATIONS_FILTER: MiddlewareSearchParams = {
  filterBy: {
    displayStatus: new Set(['STARTED']),
  },
  orderBy: 'deadline',
  orderByDirection: 'ASC',
};

export const useApplications = (baseSearchParams?: MiddlewareSearchParams, baseOffset?: number) => {
  const { page, setPage, searchParamString, searchQuery, setSearchQuery, addFilter, clearFilter } =
    useMiddlewareSearchParams(baseSearchParams || {}, baseOffset);

  const response = useSWR(baseSearchParams ? `api/application?${searchParamString}` : null, () =>
    getAllApplications(searchParamString)
  );

  const applications: ApplicationWithStatus[] = response.data?.data?.map((application: Application) => {
    const applicationWithStatus: ApplicationWithStatus = { ...application };

    applicationWithStatus.applicationStatus = mapSalesforceStatusToApplicationStatus(
      application.displayStatus,
      application.submissionStatus,
      application.notProgressedStatus
    );
    return applicationWithStatus;
  });

  return {
    ...response,
    data: applications,
    isLoading: baseSearchParams && !response.data && !response.error,
    metadata: response.data?.metadata,
    page,
    setPage,
    searchQuery,
    setSearchQuery,
    addFilter,
    clearFilter,
  };
};

export const useDraftApplicationIds = (baseSearchParams?: MiddlewareSearchParams, baseOffset?: number) => {
  const { page, setPage, searchParamString, searchQuery, setSearchQuery, addFilter, clearFilter } =
    useMiddlewareSearchParams(baseSearchParams || {}, baseOffset);

  const response = useSWR(baseSearchParams ? `api/application?${searchParamString}` : null, () =>
    getAllApplications(searchParamString)
  );

  const applications: ApplicationWithStatus[] = response.data?.data.map((application: Application) => {
    const applicationWithStatus: ApplicationWithStatus = { ...application };

    applicationWithStatus.applicationStatus = mapSalesforceStatusToApplicationStatus(
      application.displayStatus,
      application.submissionStatus,
      application.notProgressedStatus
    );
    return applicationWithStatus;
  });

  return {
    ...response,
    data: applications,
    isLoading: baseSearchParams && !response.data && !response.error,
    metadata: response.data?.metadata,
    page,
    setPage,
    searchQuery,
    setSearchQuery,
    addFilter,
    clearFilter,
  };
};

export default useApplications;
